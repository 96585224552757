.home_about {
    padding: 120px 0 140px;
}
.home_about h1 {
    margin-bottom: 24px;
    font-size: 38px;
    font-weight: bold;
    text-align: center;
}
.home_about .description {
    margin-bottom: 80px;
    font-size: 16px;
    text-align: center;
}
.home_about ul {
    display: flex;
    justify-content: space-between;
}
.home_about ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    text-align: center;
}
.home_about ul li .image_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
}
.home_about ul li:nth-child(odd) .image_box {
    background-color: #0162a2;
}
.home_about ul li:nth-child(even) .image_box {
    background-color: #636a72;
}
.home_about ul li .image_box svg {
   fill: #fff;
}
.home_about ul li div p:nth-of-type(1){
    margin-bottom: 8px;
    font-size: 30px;
    font-weight: bold;
}
.home_about ul li div p:nth-of-type(1) span {
    font-size: 18px;
}
.home_about ul li div p:nth-of-type(2) {
    font-size: 16px;
}