@charset "UTF-8";
/* reset.css */
html, body {width: 100%;height: 100%;}
body,div,dl,dt,dd,ul,li,ol,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td,table{margin:0;padding:0;}
article,aside,details,figcaption,figure,footer,header,menu,nav,section,video{display:block;}
table{border-collapse:collapse;}
img,fieldset{border:none;}
address,caption,cite,code,em,strong,th{font-style:normal;font-weight:normal;}
ol,ul{list-style:none;}
caption,th{text-align:left;}
h1,h2,h3,h4,h5,h6{font-weight:normal;font-size:100%;}
q::after,q::before{content:"";}
abbr,acronym{border:none;}
img,input{vertical-align:middle;}
hr{display:none;}
a{text-decoration: none;color: #333;}
#skipNavi,.blind{position:absolute;left:0;top:0;width:0;height:0;font-size:0;line-height:0;text-indent:-9999px;overflow:hidden;visibility:hidden;}