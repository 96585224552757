.about_vendor {
  padding-top: 100px;
}
.about_vendor section {
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.about_vendor dl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 23%;
  border: 1px solid #eee;
  background-color: #fff;
}
.about_vendor dt {
  order: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #eee;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.about_vendor dd {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
}
.about_vendor dd img {
  width: 80%;
}
