.home_reference2 {
  padding: 80px 0;
  background-color: #f8f3e9;
}
.home_reference2 .inner {
  position: relative;
}
.title_box {
  margin-bottom: 50px;
}
.title_box > p {
  padding-bottom: 4px;
  font-size: var(--font-size--8);
  font-weight: 600;
}
.title_box h1 {
  font-size: var(--font-size--3);
  font-weight: bold;
}
.title_box .more_btn {
  padding: 8px;
  position: absolute;
  right: 0;
  transform: translateY(8px);
  display: flex;
  gap: 4px;
  align-items: center;
}
.title_box .more_btn p {
  font-size: var(--font-size--10);
  font-weight: 600;
  cursor: pointer;
}
.title_box .more_btn span {
  display: flex;
  font-size: var(--font-size--9);
}
.content_box {
  display: flex;
  gap: 30px;
}
.content_box > li {
  padding: 30px 20px;
  width: calc(100% / 3);
  display: flex;
  gap: 10px;
  background-color: #fff;
  border-radius: var(--br-2);
  box-shadow: 7px 7px 7px #ddd;
  word-break: keep-all;
}
.image_box {
  display: flex;
  align-items: center;
  max-width: 70px;
}
.image_box img {
  width: 100%;
}
.text_box {
  height: 80px;
}
.text_box p:first-of-type {
  padding-bottom: 6px;
  font-size: var(--font-size--10);
  color: #ff7133;
  font-weight: bold;
}
.text_box p:last-of-type {
  font-size: var(--font-size--10);
  font-weight: 600;
}
@media screen and (max-width: 1100px) {
  .image_box {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .home_reference2 {
    padding: 60px 0;
  }
  .content_box {
    flex-direction: column;
    row-gap: 12px;
  }
  .content_box > li {
    padding: 20px 30px;
    width: 100%;
    align-items: center;
    gap: 30px;
  }
  .image_box {
    display: block;
  }
  .text_box {
    height: auto;
  }
  .title_box {
    margin-bottom: 40px;
  }
  .title_box .more_btn {
    transform: translateY(0px);
  }
}
