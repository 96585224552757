.business_outline h3 {
  padding-bottom: 20px;
  font-size: var(--font-size--8);
  font-weight: bold;
}
.business_outline p {
  font-size: var(--font-size--10);
}
@media screen and (max-width: 767px) {
  .business_outline h3 {
    padding-bottom: 12px;}
}