.besiness_consulting {
  padding-top: var(--main-top);
}
/* 이용절차 */
.business_service_process {
  padding: 70px 0;
  background-color: var(--color--blue-5);
}
.business_service_process ul {
  display: flex;
  flex-wrap: wrap;
  row-gap: 60px;
}
.business_service_process li {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.business_service_process li:nth-child(5),
.business_service_process li:nth-child(6) {
  opacity: 0.7;
}
.business_service_process li div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  border: 0.5px solid #666;
  border-radius: 50%;
}
.business_service_process li svg {
  fill: #666;
  width: 50%;
  height: 50%;
}
.business_service_process li:hover div {
  border: 0.5px solid #0079cc;
}
.business_service_process li:hover svg {
  fill: #0079cc;
}
.business_service_process li h3 {
  padding-top: 30px;
  font-size: var(--font-size--8);
  font-weight: bold;
}
.business_service_process p {
  padding-top: 40px;
  font-size: var(--font-size--12);
  color: #888;
}
/* 주요 서비스 */
.service li:nth-child(1) {
  background: url(/public/images/business/consulting/idc.png);
  background-size: cover;
}
.service li:nth-child(2) {
  background: url(/public/images/business/consulting/network.png);
  background-size: cover;
}
@media screen and (max-width: 1100px) {
  .business_service_process li {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .business_service_process {
    padding: 50px 0;
  }
  .business_service_process ul {
    row-gap: 30px;
  }
  .business_service_process li {
    width: 100%;
  }
  .business_service_process li div {
    width: 100px;
    height: 100px;
  }
}
