:root {
  /* font-size */
  --font-size--1: 80px;
  --font-size--2: 48px;
  --font-size--3: 38px;
  --font-size--4: 34px;
  --font-size--5: 32px;
  --font-size--6: 26px;
  --font-size--7: 24px;
  --font-size--8: 20px;
  --font-size--9: 18px;
  --font-size--10: 16px;
  --font-size--11: 15px;
  --font-size--12: 14px;
  --font-size--13: 13px;
  --font-size--14: 12px;

  /* color */
  --color--black-1: #000;
  --color--black-2: #333;

  --color--blue-1: #0070d2;
  --color--blue-2: #0b88e1;
  --color--blue-3: #d2d7e1;
  --color--blue-4: #eef3f6;
  --color--blue-5: #f7fafd;

  --color--gray-1: #343a40;
  --color--gray-2: #555;
  --color--gray-3: #666;
  --color--gray-4: #888;
  --color--gray-5: #a1a4aa;
  --color--gray-6: #ddd;
  --color--gray-7: #e6ecf4;
  --color--gray-8: #ededed;
  --color--gray-9: #f1f3f5;
  --color--gray-10: #f7f7f7;

  --color--white-1: #fff;
  --color--orange-1: #ff7133;
  --color--orange-2: #fed6b6;
  --color--ivory-1: #f8f3e9;

  /* font-weight */
  --font-weight-1: 900;
  --font-weight-2: bold;
  --font-weight-3: 600;
  --font-weight-4: 400;

  /* border-radius */
  --br-1: 50%;
  --br-2: 12px;
  --br-3: 8px;
  --br-4: 4px;

  /* header */
  --header-height: 80px;

  /* main section-top */
  --main-top: 60px;

  /* section */
  --section-bottom: 80px;
}
@media screen and (max-width: 1100px) {
  :root {
  }
}
@media screen and (max-width: 767px) {
  :root {
    /* font-size */
    --font-size--1: 48px;
    --font-size--2: 34px;
    --font-size--3: 28px;
    --font-size--4: 26px;
    --font-size--5: 22px;
    --font-size--6: 21px;
    --font-size--7: 20px;
    --font-size--8: 18px;
    --font-size--9: 16px;

    --br-2: 8px;
    --br-3: 6px;

    --header-height: 60px;
    --main-top: 40px;
    --section-bottom: 40px;
  }
}
