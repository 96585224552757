.tab_menu {
  margin-top: 60px;
  margin-bottom: 80px;
}
.tab_menu ul {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.tab_menu li {
  width: 100%;
}
.tab_menu a {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: var(--font-size--7);
  color: #888;
  border: 1px solid #e9ecef;
}
@media screen and (max-width: 767px) {
  .tab_menu {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .tab_menu ul {
    flex-wrap: wrap;
  }
  .tab_menu a {
    padding: 8px 0;
  }
}
