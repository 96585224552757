.business_advantages {
  padding: 70px 0;
  background-color: #f7f7f7;
}
.business_advantages h3 {
  margin-bottom: 4px;
  font-size: var(--font-size--8);
  font-weight: bold;
}
.business_advantages p {
  font-size: var(--font-size--10);
}
.business_advantages ul {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.business_advantages ul li {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  max-width: 370px;
  width: calc((100% - 60px) / 3);
  word-break: keep-all;
}
.business_advantages ul li svg {
  width: 35px;
  height: 35px;
}
.business_advantages ul li img {
  margin-bottom: 16px;
  width: 50px;
}
@media screen and (max-width: 1100px) {
  .business_advantages ul li {
    width: calc((100% - 60px) / 2);
  }
}
@media screen and (max-width: 767px) {
  .business_advantages {
    padding: 50px 0;
  }
  .business_advantages ul {
    flex-wrap: wrap;
  }
  .business_advantages ul li {
    gap: 12px;
    max-width: 100%;
    width: 100%;
  }
  .business_advantages ul li svg {
    width: 24px;
    height: 24px;
  }
}
