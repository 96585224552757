.page_title {
  position: relative;
  font-size: var(--font-size--4);
  font-weight: bold;
  margin-bottom: 40px;
  color: #000;
}
@media screen and (max-width: 767px) {
  .page_title {
    margin-bottom: 24px;
  }
  .page_title::after {
    bottom: -18px;
    width: 36px;
    height: 3px;
  }
}
