.footer {
  padding: 50px 0;
  /* background-color: #000000; */
  background-color: #343a40;
}
.terms {
  display: flex;
  gap: 20px;
}
.terms a {
  font-size: var(--font-size--12);
  font-weight: bold;
  color: #fff;
}
.company_box {
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  column-gap: 30px;
  opacity: 0.6;
}
.company_box li {
  position: relative;
  font-size: var(--font-size--12);
  color: #fff;
}
.company_box li:not(:last-of-type):after {
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  content: "";
  display: block;
  width: 1px;
  height: 13px;
  background-color: #fff;
  opacity: 0.6;
}
.copyrite {
  font-size: var(--font-size--13);
  color: #fff;
  opacity: 0.6;
}
@media screen and (max-width: 767px) {
  .company_box {
    padding: 16px 0;
    row-gap: 2px;
  }
    
}
