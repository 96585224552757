.terms_privacy2 {
  padding-top: var(--header-height);
  padding-bottom: var(--section-bottom);
}
.terms_privacy2 dl:first-of-type {
  margin-top: 20px;
}
.terms_privacy2 dl {
  margin-bottom: 20px;
}
.terms_privacy2 dt {
  font-size: var(--font-size--10);
  font-weight: 600;
}
.terms_privacy2 dd {
  font-size: var(--font-size--10);
}
