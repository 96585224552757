.about_history {
  overflow: hidden;
  padding-top: var(--main-top);
}
.year_area li {
  position: relative;
}
.year_area li::after {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 530px;
  display: block;
  content: "";
  width: 1px;
  height: 100%;
  background-color: #707070;
}
.year_area .year {
  position: relative;
  margin-left: 350px;
  width: 180px;
  font-size: var(--font-size--5);
  font-weight: bold;
}
.year_area .year.first::before {
  position: absolute;
  right: -5px;
  top: 0;
  width: 10px;
  height: 22px;
  content: "";
  background-color: #fff;
}
.year_area .year::after {
  position: absolute;
  left: 174.5px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #0079cc;
}
.year_area .month {
  position: relative;
  display: flex;
  gap: 30px;
  margin-left: 530px;
  padding-bottom: 12px;
  padding-left: 30px;
  width: 520px;
}
.year_area .month.last {
  padding-bottom: 0;
}
.year_area .month.last::before {
  position: absolute;
  left: -5px;
  top: 21px;
  display: block;
  content: "";
  width: 10px;
  height: 110px;
  background-color: #fff;
}
.year_area .month dt {
  position: relative;
  white-space: nowrap;
  font-size: var(--font-size--9);
  font-weight: 600;
}
.year_area .month dt::before {
  position: absolute;
  left: -34.5px;
  top: 12px;
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #707070;
}
.year_area .month dd {
  position: relative;
  padding-left: 8px;
  margin-bottom: 6px;
  font-size: var(--font-size--10);
}
.year_area .month dd::before {
  position: absolute;
  left: 0px;
  top: 11px;
  display: block;
  content: "";
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #666;
}
@media screen and (max-width: 1100px) {
  .year_area li::after {
    left: 270px;
  }
  .year_area .year {
    margin-left: 120px;
    width: 150px;
  }
  .year_area .year::after {
    left: 144.5px;
  }
  .year_area .month {
    margin-left: 270px;
    width: auto;
  }
  .year_area .month dt::before {
    top: 8px;
  }
  .year_area .month dd {
    width: 100%;
  }
  .year_area .month.last::before {
    top: 18px;
  }
}
@media screen and (max-width: 767px) {
  .year_area li::after {
    left: 101px;
  }
  .year_area .year {
    margin-left: 20px;
    width: 81px;
  }
  .year_area .year::after {
    left: 74.5px;
  }
  .year_area .month {
    flex-direction: column;
    gap: 4px;
    margin-left: 101px;
    padding-left: 20px;
    width: auto;
  }
  .year_area .month dt::before {
    left: -24.5px;
  }
  .year_area .month dd {
    padding-left: 16px;
    width: 100%;
  }
  .year_area .month dd::before {
    left: 8px;
  }
  .year_area .month.last::before {
    height: 160px;
  }
}
@media screen and (max-width: 370px) {
  .year_area .month.last::before {
    height: 250px;
  }
}
