.header {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: var(--header-height);
  background-color: #fff;
}
.header .inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.logo {
  display: block;
  width: 180px;
}
.logo img {
  width: 100%;
}
.menu_mobile {
  display: none;
}
.menu_pc {
  position: absolute;
  right: 0;
  display: flex;
  height: 100%;
}
.menu_pc a:hover {
  color: var(--color--blue-1);
}
.list_1dep {
  position: relative;
  display: flex;
  align-items: center;
  height: 90px;
}
.list_1dep > a {
  display: inline-block;
  padding: 0 42px;
  font-weight: 600;
  font-size: 18px;
  color: #666;
}
.list_1dep:hover .list_2dep {
  display: block;
}
.list_2dep {
  position: absolute;
  left: 0;
  top: 90px;
  padding: 42px;
  display: none;
  border-radius: 8px;
  white-space: nowrap;
  background-color: #fff;
  box-shadow: 0px 0px 2px var(--color--gray-6);
}
.list_2dep a {
  display: block;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #666;
}
.list_2dep a:last-of-type {
  margin-bottom: 0;
}
@media screen and (max-width: 1100px) {
  .list_1dep > a {
    padding: 0 22px;
  }
}
@media screen and (max-width: 767px) {
  .logo {
    width: 110px;
  }
  .menu_pc {
    display: none;
  }
  .ham_btn,
  .ham_btn span {
    display: inline-block;
    transition: all 0.4s;
  }
  .ham_btn {
    position: relative;
    left: 8px;
    width: 44px;
    height: 44px;
    cursor: pointer;
  }
  .ham_btn span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 2px;
    background-color: var(--color--gray-4);
    border-radius: 4px;
  }
  .ham_btn span:nth-child(1) {
    top: 14px;
  }
  .ham_btn span:nth-child(2) {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .ham_btn span:nth-child(3) {
    bottom: 14px;
  }
  .ham_btn.ham_active span:nth-child(1) {
    transform: translate(-14px, 7px) rotate(45deg);
  }
  .ham_btn.ham_active span:nth-child(2) {
    opacity: 0;
  }
  .ham_btn.ham_active span:nth-child(3) {
    transform: translate(-14px, -7px) rotate(-45deg);
  }
  .menu_mobile {
    display: none;
    padding-left: 24px;
    padding-bottom: 20px;
    background-color: #fff;
  }
  .menu_mobile.ham_active {
    display: block;
  }
  .menu_mobile > ul {
    margin: 0 auto;
    width: 90%;
  }
  .menu_mobile > ul > li {
    border-bottom: 1px solid var(--color--gray-9);
  }
  .menu_mobile > ul > li > a {
    position: relative;
    display: block;
    padding: 12px 15px;
    width: 100%;
    font-size: var(--font-size--9);
    font-weight: var(--font-weight-2);
    cursor: pointer;
  }
  .menu_mobile > ul > li > a.gnb_selected,
  .menu_mobile > ul > li > a:hover {
    color: var(--color--blue-1);
  }
  .menu_mobile ul li ul {
    display: none;
  }
  .menu_mobile ul li.munu_active > ul {
    display: block;
  }
  .menu_mobile ul li ul li a {
    display: block;
    padding: 15px 24px;
    color: var(--color--gray-2);
    font-size: var(--font-size--10);
    font-weight: var(--font-weight-3);
  }
  .menu_mobile ul li ul li a:hover {
    color: var(--color--blue-1);
  }
  .menu_mobile a.path_active {
    color: var(--color--blue-1);
  }
  .menu_mobile .arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 13px;
    height: 9px;
  }
  .menu_mobile .arrow::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 8px;
    height: 8px;
    border-top: 2.5px solid var(--color--black-2);
    border-right: 2.5px solid var(--color--black-2);
    border-radius: 2px;
    transform: rotate(135deg);
    transition: all 0.4s;
  }
  .menu_mobile ul li.munu_active .arrow::after {
    transform: rotate(-45deg);
  }
}
