.home_company {
  padding: 110px 0;
  /* background-color: #f3f3f3; */
  /* background: url(/public/images/main/main_company.svg) no-repeat center; */
  background: url(/public/images/main/main_company.jpg) no-repeat center;
  background-size: cover;
}
.home_company h1 {
  font-size: 38px;
  font-weight: bold;
  color: #fff;
}
.home_company p {
  padding: 30px 0 40px;
  font-size: 16px;
  color: #fff;
}
.home_company a {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-weight: bold;
}
.home_company a span {
  color: #fff;
}
