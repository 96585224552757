.customer {
  padding-top: var(--main-top);
}
.customer section {
  margin: 0 auto;
  padding: 40px;
  max-width: 800px;
  background-color: #eef3f6;
  border-radius: 4px;
}
.customer form > div:not(:last-child) {
  margin-bottom: 24px;
}
.customer .description {
  margin-bottom: 36px;
  padding-bottom: 24px;
  color: #666;
  font-size: var(--font-size--10);
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5;
}
.customer .input_box {
  display: flex;
  flex-direction: column;
}
.customer .input_box input,
select {
  height: 42px;
  padding: 0 12px;
}
.customer .input_box input,
select,
textarea {
  font-size: var(--font-size--10);
  border: 1px solid #ddd;
  border-radius: 4px;
}
.customer .input_box label {
  margin-bottom: 6px;
}
.customer select {
  position: relative;
  width: 300px;
  appearance: none;
  background-color: #fff;
  background: #fff url(/public/images/select_arrow_ico.png) no-repeat 270px 50%;
}
.customer label:not([for="agree"]) {
  font-size: var(--font-size--10);
  font-weight: bold;
}
.customer label:not([for="agree"]) span {
  position: relative;
  bottom: -3px;
  margin-right: 4px;
  color: #0070d2;
}
.customer textarea {
  padding: 20px;
  resize: none;
}
.customer .agree_box {
  margin-top: 38px;
}
.customer .agree_box .discription {
  display: none;
  margin-top: 18px;
  padding: 30px;
  background-color: #fff;
  border-radius: 4px;
}
.customer .agree_box dl:not(:last-of-type) {
  margin-bottom: 24px;
}
.customer .agree_box dt {
  font-size: var(--font-size--10);
  font-weight: bold;
}
.customer .agree_box dd {
  font-size: var(--font-size--10);
  color: #888;
}
.customer .checkbox_box {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  gap: 6px;
}
.customer .checkbox_box input {
  margin-top: 4px;
}
.customer .checkbox_box label {
  font-size: var(--font-size--11);
  cursor: pointer;
}
.customer .button_box {
  position: relative;
  margin-top: 40px;
}
.customer .button_box input {
  width: 100%;
  height: 42px;
  color: #fff;
  font-size: var(--font-size--10);
  font-weight: bold;
  background-color: #0070d2;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
.more_btn {
  font-size: var(--font-size--13);
  color: var(--color--blue-2);
}
.loading_msg {
  width: 100%;
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  text-align: center;
  color: #0070d2;
}
.error_msg {
  width: 100%;
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  text-align: center;
  color: #e52f2f;
}
@media screen and (max-width: 767px) {
  .customer section {
    padding: 30px 20px;
  }
  .customer select {
    width: 100%;
    background-position: 96% 50%;
  }
  .customer .description {
    margin-bottom: 24px;
    padding-bottom: 18px;
  }
  .customer form > div:not(:last-child) {
    margin-bottom: 14px;
  }
  .customer .agree_box {
    margin-top: 28px;
  }
  .customer .agree_box > div:not(.checkbox_box) {
    margin-top: 12px;
    padding: 20px;
  }
  .customer .agree_box dl:not(:last-of-type) {
    margin-bottom: 14px;
  }
}
