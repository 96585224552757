.about_location {
  padding-top: var(--main-top);
}
.about_location .text_box {
  margin-bottom: var(--section-bottom);
}
.about_location dl {
  display: flex;
  word-break: keep-all;
}
.about_location dt {
  position: relative;
  padding-left: 12px;
  width: 100px;
  font-size: var(--font-size--10);
  font-weight: bold;
  line-height: 1.7;
}
.about_location dt::before {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  display: block;
  content: "";
  width: 4px;
  height: 13px;
  background-color: #0079cc;
}
.about_location dd {
  font-size: var(--font-size--11);
  color: #555;
}
/* 지도 */
.map_image {
  width: 100%;
  height: 500px;
}
.map_image img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .about_location .text_box {
    margin-bottom: 15px;
  }
  .about_location dl {
    padding-bottom: 4px;
    align-items: flex-start;
  }
  .about_location dt {
    width: 70px;
  }
  .about_location dd {
    flex: 1;
    line-height: 1.7;
  }
}
