.description h3 {
  padding-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
}

.description > ul > li {
  padding: 40px 30px;
}
.description > ul > li:nth-child(odd) {
  background-color: #f3f3f3;
}
.description > ul > li ul li {
    position: relative;
    padding-left: 12px;
    line-height: 1.6;
}
.description > ul > li ul li::after {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-50%);
  display: block;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #333;
}
