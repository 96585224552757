.page_header {
  padding-top: var(--header-height);
  width: 100%;
  height: 370px;
}
.page_header h1 {
  padding: 90px 0 16px;
  font-size: var(--font-size--2);
  font-weight: bold;
  color: #fff;
}
.page_header p {
  font-size: var(--font-size--9);
  color: #fff;
  line-height: 1.2;
}
@media screen and (max-width: 767px) {
  .page_header {
    height: 280px;
  }
  .page_header h1 {
    padding: 65px 0 10px;
  }
}