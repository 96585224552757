.home_business {
  padding: 60px 0 80px;
}
.home_business ul {
  display: flex;
  gap: 20px;
}
.home_business ul li {
  width: 25%;
  height: 380px;
  background-color: #e6ecf4;
  border-radius: 8px;
  background-size: 170px;
  background-position: 50% 65%;
  background-repeat: no-repeat;
}
.home_business ul li:hover {
  background-size: 187px;
  transition: all 0.3s linear;
}

.home_business ul li:nth-child(1) {
  background-image: url(/public/images/main/main_business_1.svg);
}
.home_business ul li:nth-child(2) {
  background-image: url(/public/images/main/main_business_4.svg);
}
.home_business ul li:nth-child(3) {
  background-image: url(/public/images/main/main_business_32.svg);
}
.home_business ul li:nth-child(4) {
  background-image: url(/public/images/main/main_business_42.svg);
}
.home_business ul li a {
  position: relative;
  display: block;
  padding: 24px 32px;
  width: 100%;
  height: 100%;
}
.home_business h2 {
  margin-bottom: 10px;
  font-size: var(--font-size--7);
  font-weight: bold;
  cursor: pointer;
}
.home_business p {
  font-size: var(--font-size--10);
  cursor: pointer;
  word-break: keep-all;
}
.home_business .more_btn {
  position: absolute;
  padding: 0 32px;
  width: 100%;
  left: 0;
  bottom: 24px;
  font-size: var(--font-size--12);
  font-weight: bold;
}
.home_business .more_btn div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1100px) {
  .home_business ul li {
    width: 25%;
    height: 340px;
    background-size: 140px;
  }
  .home_business ul li:hover {
    background-size: 154px;
  }
  .home_business ul li a {
    padding: 20px 14px;
  }
  .home_business .more_btn {
    bottom: 20px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .home_business {
    padding: 40px 0 60px;
  }
  .home_business ul {
    flex-direction: column;
    gap: 10px;
  }
  .home_business ul li {
    width: 100%;
    height: 160px;
    background-position: 90% 50%;
  }
  .home_business ul li a {
    padding: 20px 30px;
  }
  .home_business .more_btn {
    padding: 0 30px;
  }
  .home_business .more_btn div {
    gap: 12px;
    justify-content: flex-start;
  }
}
