.employment {
  padding-top: var(--main-top);
}
.employment .title span {
  position: relative;
  display: inline-block;
  font-size: var(--font-size--4);
  font-weight: bold;
  margin-bottom: 53px;
  color: #000;
}
.employment .title span::after {
  position: absolute;
  left: 0;
  bottom: -8px;
  display: block;
  content: "";
  width: 100%;
  height: 3px;
  background-color: #000;
}
.employment .image_box {
  width: 40%;
  height: 400px;
  background: url(/public/images/employment_2.jpg) no-repeat center;
  background-size: cover;
}
/* 채용절차 */
.process ol {
  display: flex;
  gap: 10px;
  width: 100%;
}
.process ol li {
  padding: 20px;
  width: 25%;
  border: solid 1px var(--color--gray-7);
}
.process ol li p:nth-child(1) {
  font-size: var(--font-size--8);
  font-weight: bold;
  color: #0070d2;
}
.process ol li p:nth-child(2) {
  padding-bottom: 12px;
  font-size: var(--font-size--7);
  font-weight: bold;
}
.process ol li p:nth-child(3) {
  font-size: var(--font-size--10);
}
/* 복리후생 */
.benefit {
  display: flex;
  gap: 30px;
}
.benefit dl {
  display: flex;
  flex-direction: column;
  width: 25%;
  word-break: keep-all;
  font-size: var(--font-size--9);
}
.benefit dt {
  padding: 20px 0 4px;
  order: 1;
  font-size: var(--font-size--8);
  font-weight: bold;
}
.benefit .benefit_image {
  order: 0;
  width: 100%;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
}
.benefit dl:nth-child(1) .benefit_image {
  background-image: url(/public/images/employment/benefit_1.jpg);
}
.benefit dl:nth-child(2) .benefit_image {
  background-image: url(/public/images/employment/benefti_2.jpg);
}
.benefit dl:nth-child(3) .benefit_image {
  background-image: url(/public/images/employment/benefit_3.jpg);
}
.benefit dl:nth-child(4) .benefit_image {
  background-image: url(/public/images/employment/benefit_4.jpg);
}
.benefit dd:last-of-type {
  order: 2;
  padding-top: 0;
  font-size: var(--font-size--10);
}
@media screen and (max-width: 1100px) {
  .process ol {
    flex-direction: column;
  }
  .process ol li {
    padding: 14px 20px;
    width: 100%;
  }
  .process ol li p:nth-child(2) {
    padding-bottom: 8px;
  }
}
@media screen and (max-width: 767px) {
  .employment .title span {
  margin-bottom: 43px;
}
  .process ol li {
    padding: 14px;
  }
  .benefit {
    flex-direction: column;
    gap: 30px;
  }
  .benefit dl {
    width: 100%;
  }
  .benefit dt {
    padding: 10px 0 2px;
  }
  .benefit .benefit_image {
    height: 130px;
  }
}
