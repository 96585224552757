.terms_nav {
  padding: 50px 0;
  display: flex;
  justify-content: space-around;
  font-size: var(--font-size--10);
  font-weight: 600;
}
.terms_nav li {
  width: 100%;
  height: 54px;
}
.terms_nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color--gray-6);
}
.terms_nav a.termsnav_active {
  color: var(--color--blue-1);
  border: 1px solid var(--color--blue-1);
}
@media screen and (max-width: 767px) {
  .terms_nav {
    flex-direction: column;
  }
  .terms_nav li {
    height: 44px;
  }
}