.page_title3 {
  margin-bottom: 50px;
}
.page_title3 h2.center {
  text-align: center;
}
.page_title3 span {
  position: relative;
  font-size: var(--font-size--4);
  font-weight: bold;
  margin-bottom: 40px;
  color: #000;
}
.page_title3 span::after {
  position: absolute;
  left: 0;
  bottom: -20px;
  display: block;
  content: "";
  width: 118px;
  height: 7px;
  background-color: #0079cc;
}
.page_title3 h2.center span::after {
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .page_title3 span {
    margin-bottom: 48px;
  }
  .page_title3 span::after {
    bottom: -18px;
    width: 85px;
    height: 5px;
  }
}
