.business_service ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.business_service ul li {
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: calc((100% - 20px) / 2);
  height: 200px;
  background-color: #eee;
  text-align: center;
}
.business_service ul li h3 {
  font-size: var(--font-size--7);
  font-weight: bold;
  color: #fff;
}
.business_service ul li p {
  color: #fff;
  font-size: var(--font-size--10);
}
@media screen and (max-width: 767px) {
  .business_service ul {
    gap: 10px;
  }
  .business_service ul li {
    padding: 30px;
    width: 100%;
    height: 160px;
  }
}