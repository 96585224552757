.about_message {
  padding-top: var(--main-top);
}
.contens_box {
  display: flex;
  gap: 70px;
  align-items: center;
  padding-top: 10px;
}
.contens_box .image {
  width: 45%;
  height: 450px;
  background: url(/public/images/about/about_message.jpg) no-repeat center;
  background-size: cover;
  border-radius: 4px;
}
.contens_box .text_box {
  width: 45%;
}
.contens_box .text_box .message p {
  padding-bottom: 14px;
  line-height: 1.5;
  font-size: var(--font-size--10);
}
.contens_box .text_box .signature {
  font-size: var(--font-size--8);
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .contens_box {
    gap: 30px;
    flex-direction: column;
    padding-top: 0px;
  }
  .contens_box .image {
    width: 100%;
    height: 200px;
  }
  .contens_box .text_box {
    width: 100%;
  }
  .contens_box .text_box .message p {
    padding-bottom: 12px;
  }
}
