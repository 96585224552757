/* .topmenu_list a {
  color: #333;
  font-weight: 600;
}
.topmenu_list a.active {
  color: #fff;
  background-color: #0b5b96;
} */
.topmenu_list a.active {
  font-size: var(--font-size--7);
  color: var(--color--blue-1);
  border: 1px solid var(--color--blue-1);
  font-weight: bold;
}
