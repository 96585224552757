.tab_menu {
  margin-bottom: 80px;
  height: 60px;
  background-color: #f3f3f3;
}
.tab_menu ul {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.tab_menu li {
  width: 100%;
  height: 100%;
}
.tab_menu a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
